<template>
    <ion-item>
            <ion-thumbnail slot="start">
                <ion-img :src="'/assets/' + item.image" :alt="item.name"></ion-img>
            </ion-thumbnail>
            <ion-label>
                {{item.name}}
                <br/>
                <ion-chip v-for="custom in item.customizations.filter(c => c.added)" :key="custom.name" color="primary">
                    <ion-label>{{custom.name}}</ion-label>
                </ion-chip>
            </ion-label>
            <ion-button @click='removeOrderReviewItem'>Remove</ion-button>
    </ion-item>
</template>

<script>
//import { IonItem, IonThumbnail, IonImg, IonLabel, IonButton } from '@ionic/vue';

export default {
    props: ["item"],
    components: {
        // IonItem, 
        // IonThumbnail, 
        // IonImg, 
        // IonLabel,
        // IonButton
    },
    methods: {
        removeOrderReviewItem() {
            this.$store.dispatch('removeOrderReviewItem', this.item)
        }
    }
}
</script>