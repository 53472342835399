<template>
<base-layout page-title="User Profile" page-default-back-link="/">
  <base-layout title="Your Order">
     <ion-list>
          <order-reveiw-list-item
              v-for="item in orderItems" 
              :key="item.id"
              :item="item"
              >
          </order-reveiw-list-item>
      </ion-list>
      <ion-button @click="placeOrder" v-if="canPlaceOrder && orderItems.length > 0 && orderItems.length < maxItems + 1">Place Order</ion-button>
      <div v-if="orderItems.length == 0">Your cart is empty</div>
      <div v-else-if="orderItems.length > maxItems">For the sake of the baristas we ask that you please keep your order to only {{maxItems}} items, please remove some items from your order</div>
      <span v-if="canPlaceOrder == false">So Sorry, we are not accepting orders at this time.</span>
  </base-layout>
</base-layout>
</template>

<script>
//import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
//import { IonList, IonButton } from '@ionic/vue';
import OrderReveiwListItem from '../components/OrderReveiwListItem.vue';
export default  {
  name: 'Order',
  components: { OrderReveiwListItem },
  data() {
    return {
      
    };
  },
  computed: {
    order() {
      return this.$store.getters.orderReview;
    },
    canPlaceOrder() {
      return this.$store.getters.canPlaceOrder;
    },
    orderItems() {
      return this.$store.getters.orderReview.items;
    },
    maxItems() {
      return this.$store.getters.store.maxItemsPerOrder;
    }
  },
  methods: {
    placeOrder() {
      this.$store.dispatch('placeOrder', this.order);
      this.$store.dispatch('emptyOrderReview');
      this.$router.push('/tabs/userqueue');
    },
  },
  mounted() {
    if (this.$store.getters.orders == null) {
      this.$store.dispatch('loadOrders');
      this.$store.dispatch('loadNotCancelled');
    }
  }
}
</script>